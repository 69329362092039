import React from "react"
import themeConfig from "../configs/themeConfig"
import classnames from "classnames"

const FullPageLayout = ({ children, ...rest }) => {
  return (

              <main className="main w-100">{children}</main>

  )
}

export default FullPageLayout
