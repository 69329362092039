import React from "react"
import * as Icon from "react-feather"
import {IoWalletOutline,MdCardGiftcard} from "react-icons/all"
const navigationConfig = [
  {
    id: "home",
    title: "Home",
    type: "item",
    icon: <Icon.Home size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/"
  },
  {
    id: "page8",
    title: "Orders",
    type: "item",
    icon: <Icon.List size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/AllOrdersPage"
  },{
    id: "page5",
    title: "Transactions",
    type: "item",
    icon: <Icon.Repeat size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/transactionspage"
  },{
    id: "page6",
    title: "Cart",
    type: "item",
    icon: <Icon.ShoppingCart size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/cartpage"
  },{
        id: "page7",
        title: "Voucher",
        type: "item",
        icon: <Icon.Gift size={20} />,
        permissions: ["admin", "editor"],
        navLink: "/voucher"
    }
    ,{
        id: "page9",
        title: "Referral",
        type: "item",
        icon: <IoWalletOutline size={20} />,
        permissions: ["admin", "editor"],
        navLink: "/referral"
    }
    ,{
        id: "page10",
        title: "giftCards",
        type: "item",
        icon: <MdCardGiftcard size={20} />,
        permissions: ["admin", "editor"],
        navLink: "/gifts"
    }

  // {
  //   id: "page2",
  //   title: "progress",
  //   type: "item",
  //   icon: <Icon.BookOpen size={20} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/page2"
  // }
  // ,
  // {
  //   id: "page3",
  //   title: "Active course",
  //   type: "item",
  //   icon: <Icon.BookOpen size={20} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/page3"
  // },

]

export default navigationConfig
